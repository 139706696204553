<template>
    <div>
        <div class="ft20 cl-main ftw600">消息通知</div>

        <div class="mt20">
            <div class="bg-w">
                <a-layout>
                    <a-layout-header class="layout-classes-header">
                        <a-menu class="classes-menu" :defaultSelectedKeys="[selectCurrent]" @click="selectMenu" mode="horizontal">
                            <a-menu-item key="server">服务人员通知</a-menu-item>
                            <a-menu-item key="user">客户端用户通知</a-menu-item>
                        </a-menu>
                    </a-layout-header>
                    <a-layout-content class="layout-classes-content">
                        <div v-if="selectCurrent === 'server'">
                            <server-pages></server-pages>
                        </div>
                        <div v-if="selectCurrent === 'user'">
                            <user-pages></user-pages>
                        </div>

                    </a-layout-content>
                </a-layout>
            </div>
        </div>
    </div>
</template>

<script>
import serverPages from './components/notice/server.vue';
import userPages from './components/notice/user.vue';
export default{
    components:{
        serverPages,
        userPages,
    },
    data(){
        return {
            selectCurrent:'server',
        }
    },
    methods:{
        selectMenu(e) {
            this.selectCurrent = e.key;
        },
    }
}
</script>

<style scoped>
.layout-classes-header {
    background: #FFFFFF;
}

.layout-classes-header {
    padding: 0 20px;
    height: 58px;
    line-height: 57px;
    border-bottom: 1px solid #F0F3F5;
    width: 100%;
}

.layout-classes-content {
    padding: 0 0 10px 0;
    background: #FFFFFF;
    width: 100%;
    min-height: 700px;
}

.classes-menu {
    border-bottom: none;
    line-height: 55px;
    font-size: 14px;
}
</style>
