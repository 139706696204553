<template>
    <div>
        <a-modal :title="getTitle" :width="1200" :visible="visible" :footer="null" @cancel="handleCancel">
        <div class="pd30">
            <div class="mt20">
                <div class="form-search-box ">
                    <a-form layout="inline">
                        <a-form-item label="关键词" v-if="support===2">
                            <a-input v-model="search.keywords" placeholder="请输入内容"></a-input>
                        </a-form-item>
                        <a-form-item label="关键词" v-else>
                            <a-input v-model="search.keywords" placeholder="请输入内容、标题"></a-input>
                        </a-form-item>
                        <a-form-item label="手机号" v-if="support===2">
                            <a-input v-model="search.mobile" placeholder="请输入手机号"></a-input>
                        </a-form-item>
                        <a-form-item label="预约时间">
                            <a-range-picker @change="changeNoticeTime" v-model="search.notice_time"  format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="searchAct" type="primary">查询</a-button>
                            <a-button @click="cancelAct" class="ml10">取消</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="wxb-table-gray">
                    <a-table rowKey="id"
                             :columns="columns"
                             :pagination="pagination"
                             @change="handleTableChange"
                             :data-source="datas"
                             :loading="loading"
                             :scroll="{ x: 1200 }">

                        <div class="flex center" slot="send_status" slot-scope="send_status">
                            <a-tag v-if="send_status == 0" color="orange">
                                发送中
                            </a-tag>
                            <a-tag v-if="send_status == 1" color="green">
                                正常
                            </a-tag>
                            <a-tag v-if="send_status == 2" color="red">
                                发送失败
                            </a-tag>
                        </div>
                        <div class="flex center" slot="content_txt" slot-scope="content_txt">
                            <div v-html="pretifyJson(content_txt)"></div>
                        </div>
                        <div class="flex center" slot="extra" slot-scope="extra">
                            <div v-html="pretifyJson(extra)"></div>
                        </div>
<!--                        <template slot="action" slot-scope="record,index">-->
<!--                            <div class="flex center">-->
<!--                                <a-dropdown placement="bottomRight">-->
<!--                                      <span class="more-act">-->
<!--                                        <i class="iconfont iconmore_gray"></i>-->
<!--                                      </span>-->
<!--                                    <a-menu slot="overlay">-->
<!--                                        <a-menu-item>-->
<!--                                            <a class="menu-act" href="javascript:;" @click="del(record)">-->
<!--                                                <i class="iconfont ft14 icondelete"></i>-->
<!--                                                <span class="ml10">删除</span>-->
<!--                                            </a>-->
<!--                                        </a-menu-item>-->
<!--                                    </a-menu>-->
<!--                                </a-dropdown>-->
<!--                            </div>-->
<!--                        </template>-->
                    </a-table>
                </div>
            </div>
        </div>
        </a-modal>
    </div>
</template>

<script>
import {listMixin} from '@/common/mixin/list.js';

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        scene:{
            type:Number,
            default:0
        },
        support:{
            type:Number,
            default:0
        }
    },
    mixins: [listMixin],
    data() {
        return {
            loading: false,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            search: {
                keywords: '',
                mobile: '',
                notice_time:[],
            },
            columns: [
                {title: 'ID',dataIndex: 'id',align: 'center',width:100 , fixed: 'left'},
                {title: '标题',dataIndex: 'title',align: 'center',width:150, fixed: 'left'},
                {title: '通知对象',dataIndex:'notice_obj',align: 'center',width:170},
                {title: '内容',dataIndex: 'content_txt',align: 'center',width:500, scopedSlots: {customRender: 'content_txt'}},
                {title: '通知反馈',dataIndex: 'extra',align: 'center',width:400, scopedSlots: {customRender: 'extra'}},
                {title: '通知时间',dataIndex: 'create_time',align: 'center',width:160, fixed: 'right'},
                // {title: '操作',key: 'operation',align: 'center',scopedSlots: {customRender: 'action'},fixed: 'right'},
            ],
            datas: [],
        }
    },
    computed:{
        getAccountUrl(){
            return process.env.VUE_APP_ACCOUNT_URL
        }
    },
    created() {
        if(this.support === 1){
            this.getTitle = '系统通知';
        }
        if(this.support === 2){
            this.getTitle = '短信通知';
            this.columns = [
                {title: 'ID',dataIndex: 'id',align: 'center',width:100, fixed: 'left'},
                {title: '标题',dataIndex: 'title',align: 'center',width:150, fixed: 'left'},
                {title: '通知对象',dataIndex:'mobile',align: 'center',width:170},
                {title: '内容',dataIndex: 'content',align: 'center'},
                {title: '通知反馈',dataIndex: 'extra',align: 'center'},
                {title: '通知时间',dataIndex: 'create_time',align: 'center',width:160},
                {title: '状态',dataIndex: 'send_status',align: 'center',width:100, scopedSlots: {customRender: 'send_status'}},
                {title: '操作',key: 'operation',align: 'center',scopedSlots: {customRender: 'action'},fixed: 'right'},
            ];
        }
        if(this.support === 3){
            this.getTitle = '微信模板消息';
        }
        if(this.support === 4){
            this.getTitle = '微信小程序通知';
        }
    },
    methods: {
        /**
         * 取消弹窗
         */
        handleCancel() {
            this.$emit("cancel");
        },
        getLists(){
            if(this.loading===true) return;
            this.loading=true;
            this.$http.api('platform/notice/recordList',{
                limit:this.pagination.pageSize,
                page:this.pagination.current,
                scene:this.scene,
                send_type:this.support,
                keywords:this.search.keywords,
                mobile:this.search.mobile,
                notice_time:this.search.notice_time,
            }).then(res=>{
                this.pagination.total=res.total;
                this.datas=res.list;
                this.loading=false;
            }).catch(res=>{
                console.log(res);
                this.loading=false;
            })
        },
        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },
        changeNoticeTime(value){
            this.search.notice_time = value;
        },
        pretifyJson(json) {
            if (typeof json !== 'string') {
                json = JSON.stringify(json, undefined, 4);
            }
            let jsonStr = json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
                function (match) {
                    let cls = "<span>";
                    if (/^"/.test(match)) {
                        if (/:$/.test(match)) {
                            cls = "<span class='key'>";
                        } else {
                            cls = "<span class='string'>";
                        }
                    } else if (/true|false/.test(match)) {
                        cls = "<span class='boolean'>";
                    } else if (/null/.test(match)) {
                        cls = "<span class='null'>";
                    }
                    return cls + match + "</span>";
                }
            );

            let line = '';
            // 换行方式1 （低版本浏览器会出现死循环）
            jsonStr = jsonStr.replaceAll('</span>,', '</span>,' + line)
                .replaceAll('{<span', '{' + line + '<span')
                .replaceAll('</span>}', '</span>' + line + '}')

            // 换行方式2 （建议使用）
            jsonStr = jsonStr.split('</span>,').join('</span>,' + line)
                .split('{<span').join('{' + line + '<span')
                .split('</span>}').join('</span>' + line + '}');

            return jsonStr;
        }
    }
}
</script>

<style>
#data_info{
    width: 100%;
    font-size: 16px;
    white-space: pre-wrap;
    word-wrap: break-word;
    border:0px;
}
span.string { color: green; }
span.number { color: darkorange; }
span.boolean { color: blue; }
span.null { color: magenta; }
span.key { color: red; }
</style>
