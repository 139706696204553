<template>
    <div>
        <a-modal :title="getTitle" :width="1000" :visible="visible" @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
                    保存
                </a-button>
            </template>
            <a-spin :spinning="loading">
                <div>
                    <div class="mt20 bg-w pd40">

                        <a-form-model v-if="support === 1" :model="form"  :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-model-item ref="scene_desc" label="通知场景" :hasFeedback="true" prop="scene_desc">
                                <a-input placeholder="请输入通知场景" v-model="form.scene_desc" disabled="disabled"></a-input>
                            </a-form-model-item>

                            <a-form-model-item ref="title" required label="通知标题" :hasFeedback="true" prop="title">
                                <a-input placeholder="请输入通知标题" v-model="form.system_notice.title"></a-input>
                            </a-form-model-item>

                            <a-form-model-item ref="variable"  help="系统在当前场景预定义好的通知变量" label="场景变量" prop="variable">
                                <a-tag v-for="(val,index) in form.variable" color="purple" :key="index">
                                    {{val}}
                                </a-tag>
                            </a-form-model-item>

                            <a-form-model-item label="通知内容" required>
                                <a-input v-model="form.system_notice.content" type="textarea"/>
                            </a-form-model-item>

                            <a-form-model-item required label="状态" help="开启或关闭当前场景通知">
                                <a-radio-group v-model="form.system_notice.status">
                                    <a-radio :value="1">开启</a-radio>
                                    <a-radio :value="0">关闭</a-radio>
                                </a-radio-group>
                            </a-form-model-item>

                        </a-form-model>

                        <a-form-model v-else-if="support === 2" :model="form"  :label-col="labelCol" :wrapper-col="wrapperCol" >
                            <a-form-model-item ref="scene_desc" label="通知场景" :hasFeedback="true" prop="scene_desc" >
                                <a-input placeholder="请输入通知场景" v-model="form.scene_desc" disabled="disabled"></a-input>
                            </a-form-model-item>

                            <a-form-model-item ref="template_code" help="需要与短信接口的模板ID一致" required label="模板ID" :hasFeedback="true" prop="template_code">
                                <a-input placeholder="请输入短信模板ID" v-model="form.sms_notice.template_code"></a-input>
                            </a-form-model-item>

                            <a-form-model-item ref="variable" help="系统在当前场景预定义好的通知变量"  label="场景变量" prop="variable">
                                <a-tag v-for="(val,index) in form.variable" color="purple" :key="index">
                                    {{val}}
                                </a-tag>
                            </a-form-model-item>

                            <a-form-model-item label="通知内容" required>
                                <a-input v-model="form.sms_notice.content" type="textarea"/>
                            </a-form-model-item>

                            <a-form-model-item required label="状态" help="开启或关闭当前场景通知">
                                <a-radio-group v-model="form.sms_notice.status">
                                    <a-radio :value="1">开启</a-radio>
                                    <a-radio :value="0">关闭</a-radio>
                                </a-radio-group>
                            </a-form-model-item>

                        </a-form-model>
                        <a-form-model v-else-if="support === 4" :model="form"  :label-col="labelCol" :wrapper-col="wrapperCol" >
                            <a-form-model-item ref="scene_desc" label="通知场景" :hasFeedback="true" prop="scene_desc">
                                <a-input placeholder="请输入通知场景" v-model="form.scene_desc" disabled="disabled"></a-input>
                            </a-form-model-item>

                            <a-form-model-item ref="template_id"  required label="模板编号" :hasFeedback="true" prop="template_id">
                                <a-input placeholder="请输入模板编号" v-model="form.mnp_notice.template_id"></a-input>
                            </a-form-model-item>

                            <a-form-model-item ref="name"  required label="模板名称" :hasFeedback="true" prop="name">
                                <a-input placeholder="请输入模板名称" v-model="form.mnp_notice.name"></a-input>
                            </a-form-model-item>
                            <a-form-model-item ref="template_sn"  required label="模板ID" :hasFeedback="true" prop="template_sn">
                                <a-input placeholder="请输入模板ID" v-model="form.mnp_notice.template_sn"></a-input>
                            </a-form-model-item>


                            <a-form-model-item ref="variable" help="系统在当前场景预定义好的通知变量"  label="场景变量" prop="variable">
                                <a-tag v-for="(val,index) in form.variable" color="purple" :key="index">
                                    {{val}}
                                </a-tag>
                            </a-form-model-item>

<!--                            <a-form-model-item label="通知内容" required>-->
<!--                                <a-input v-model="form.content" type="textarea"/>-->
<!--                            </a-form-model-item>-->

                            <a-form-model-item required label="状态" help="开启或关闭当前场景通知">
                                <a-radio-group v-model="form.mnp_notice.status">
                                    <a-radio :value="1">开启</a-radio>
                                    <a-radio :value="0">关闭</a-radio>
                                </a-radio-group>
                            </a-form-model-item>

                        </a-form-model>

                    </div>
                </div>
            </a-spin>
        </a-modal>
    </div>
</template>

<script>

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        scene:{
            type:Number,
            default:0
        },
        support:{
            type:Number,
            default:0
        }
    },
    data() {
        return {
            loading: false,
            confirmLoading: false,
            labelCol: {span: 4},
            wrapperCol: {span: 20},
            form: {
                scene_desc:'',
                scene: 0,
                variable:[],
                system_notice:{
                    title:'',
                    content:'',
                    status:0,
                },

                sms_notice:{
                    template_code:'',
                    content:'',
                    status:0,
                },
                mnp_notice:{
                    name:'',
                    template_sn:'',
                    template_id:'',
                    status:0,
                    tpl:{}
                }
            },
        }
    },
    computed: {

    },
    created() {
        if(this.support === 1){
            this.getTitle = '系统通知编辑';
        }
        if(this.support === 2) {
            this.getTitle = '短信通知编辑';
        }
        if(this.support === 3){
            this.getTitle = '微信模板消息编辑';
        }
        if(this.support === 4){
            this.getTitle = '微信小程序通知编辑';
        }
        this.form.scene = this.scene;
        this.loaddata();
    },
    methods: {
        loaddata() {

            if (this.loading === true) return;
            this.loading = true;
            this.$http.api('platform/notice/getNoticeInfo', {
                scene: this.scene,
                type: this.support,
            }).then(res => {
                if (res) {
                    this.form = res;
                }
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },
        /**
         * 取消弹窗
         */
        handleCancel() {
            this.$emit("cancel");
        },
        /**
         * 提交数据
         */
        onSubmit() {
            if (this.confirmLoading === true) return;
            this.confirmLoading = true;
            this.form.supportType = this.support;
            this.$http.api('platform/notice/saveNoticeData', this.form).then(res => {
                this.$message.success('保存成功', 1, () => {
                    this.confirmLoading = false;
                    this.$emit("ok");
                })

            }).catch(res => {
                console.log(res);
                this.confirmLoading = false;
            })
        },

    }
}
</script>
<style>
.ant-input-disabled{
    color: #3D445D;
}
</style>

