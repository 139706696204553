<template>
    <div>
        <div class="pd30">
            <div class="mt20">
                <div class="wxb-table-gray">
                    <a-table rowKey="id" :columns="columns" :pagination="pagination"
                             @change="handleTableChange" :data-source="datas"
                             :loading="loading">

                        <div class="flex center" slot="system_notice_data" slot-scope="record">
                            <div v-if="record.system_notice">
                                <!--                            状态-->
                                <a-tag v-if="record.system_notice.status == 1" color="green">
                                    已启用
                                </a-tag>
                                <a-tag v-if="record.system_notice.status == 0" color="red">
                                    已关闭
                                </a-tag>
                                <!--                            按钮-->
                                <a-button type="primary" icon="search" @click="getNoticeRecord(record,1)" size="small" style="margin-right: 6px;">
                                    通知记录
                                </a-button>
                                <a-button type="primary" icon="form" size="small" @click="editNotice(record,1)">
                                    设置
                                </a-button>
                            </div>
                        </div>

                        <div class="flex center" slot="sms_notice_data" slot-scope="record">
                            <div v-if="record.sms_notice">
                                <!--                            状态-->
                                <a-tag v-if="record.sms_notice.status == 1" color="green">
                                    已启用
                                </a-tag>
                                <a-tag v-if="record.sms_notice.status == 0" color="red">
                                    已关闭
                                </a-tag>
                                <!--                            按钮-->
                                <a-button type="primary" icon="search" size="small" @click="getNoticeRecord(record,2)" style="margin-right: 6px;">
                                    通知记录
                                </a-button>
                                <a-button type="primary" icon="form" size="small" @click="editNotice(record,2)">
                                    设置
                                </a-button>
                            </div>
                        </div>

                        <div class="flex center" slot="mnp_notice_data" slot-scope="record">
                            <div v-if="record.mnp_notice">
                                <!--                            状态-->
                                <a-tag v-if="record.mnp_notice.status == 1" color="green">
                                    已启用
                                </a-tag>
                                <a-tag v-if="record.mnp_notice.status == 0" color="red">
                                    已关闭
                                </a-tag>
                                <!--                            按钮-->
                                <a-button type="primary" icon="search" size="small" @click="getNoticeRecord(record,4)" style="margin-right: 6px;">
                                    通知记录
                                </a-button>
                                <a-button type="primary" icon="form" size="small" @click="editNotice(record,4)">
                                    设置
                                </a-button>
                            </div>
                        </div>


                    </a-table>
                </div>
            </div>

            <div v-if="recordLoading">
                <record-pages :scene="scene" @cancel="cancelRecord" @ok="okRecord" :visible="recordLoading" :support="support"></record-pages>
            </div>
            <div v-if="editLoading">
                <edit-pages :scene="scene" :visible="editLoading" :support="support"
                            @cancel="cancelEdit" @ok="okEdit" ></edit-pages>
            </div>
        </div>
    </div>
</template>

<script>
import {listMixin} from '@/common/mixin/list.js';
import recordPages from "./record.vue";
import editPages from "./edit.vue";

export default {
    components: {
        recordPages,
        editPages,
    },
    mixins: [listMixin],
    data() {
        return {
            loading: false,
            recordLoading: false,
            editLoading: false,
            id: 0,
            scene: 0,
            support: 0,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            columns: [
                {title: '标记', dataIndex: 'scene', align: 'center'},
                {title: '通知类型', dataIndex: 'scene_desc', align: 'center'},
                {title: '系统通知', key: 'system_notice_data', align: 'center', scopedSlots: {customRender: 'system_notice_data'}},
                {title: '短信通知', key: 'sms_notice_data', align: 'center', scopedSlots: {customRender: 'sms_notice_data'}},
                {title: '微信小程序通知', key: 'mnp_notice_data', align: 'center', scopedSlots: {customRender: 'mnp_notice_data'}},
                // {title: '微信模板消息', dataIndex: 'name', align: 'center'},
            ],
            datas: [],
        }
    },
    computed: {
        getAccountUrl() {
            return process.env.VUE_APP_ACCOUNT_URL
        }
    },
    created() {

    },
    methods: {
        getLists() {
            if (this.loading === true) return;
            this.loading = true;
            this.$http.api('platform/notice/getNoticeList', {
                limit: this.pagination.pageSize,
                page: this.pagination.current,
                type: 1,
            }).then(res => {
                this.pagination.total = res.total;
                this.datas = res.list;
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },
        getNoticeRecord(record,support){

            // eslint-disable-next-line no-mixed-spaces-and-tabs
            this.id = record.id;
            this.scene = record.scene;
            this.support = support;
            // eslint-disable-next-line no-mixed-spaces-and-tabs
            this.recordLoading = true;
        },
        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },
        editNotice(record,support){
            // eslint-disable-next-line no-mixed-spaces-and-tabs
            this.id = record.id;
            this.scene = record.scene;
            this.support = support;
            // eslint-disable-next-line no-mixed-spaces-and-tabs
            this.editLoading = true;
        },
        cancelEdit() {
            this.editLoading = false;
        },
        okEdit() {
            this.editLoading = false;
        },


        cancelRecord() {
            this.recordLoading = false;
        },
        okRecord() {
            this.recordLoading = false;
        },
    }
}
</script>

<style>
.notice_status .ant-tag{
    margin-top: 6px;
}
</style>
